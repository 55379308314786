(function (root, factory) {
        if (typeof define === 'function' && define.amd) {
            // AMD. Register as an anonymous module.
            define([], factory);
        } else if (typeof module === 'object' && module.exports) {
            // Node. Does not work with strict CommonJS, but
            // only CommonJS-like environments that support module.exports,
            // like Node.
            module.exports = factory();
        } else {
            // Browser globals (root is window)
            root.returnExports = factory();
        }
    }(typeof self !== 'undefined' ? self : this, function () {
        // Just return a value to define the module export.
        // This example returns an object, but the module
        // can return a function as the exported value.
        'use strict';
        var skins = {};
         skins['wysiwyg.common.components.stripSlideShowSlide.viewer.skins.stripSlideShowSlideSkin'] = {
  "react": [
    [
      "div",
      "background",
      [],
      {},
      [
        "div",
        "backgroundMedia",
        [],
        {}
      ]
    ],
    [
      "div",
      "borderNode",
      [],
      {}
    ],
    [
      "div",
      "inlineContentParent",
      [],
      {},
      [
        "div",
        "inlineContent",
        [],
        {}
      ]
    ]
  ],
  "params": {
    "brw": "BORDER_SIZE",
    "brd": "BORDER_COLOR_ALPHA"
  },
  "paramsDefaults": {
    "brw": "0",
    "brd": "color_11"
  },
  "css": {
    "%borderNode": "border:[brw] solid [brd];position:absolute;top:0;right:0;bottom:0;left:0;pointer-events:none;",
    "%inlineContent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%inlineContentParent": "position:absolute;top:0;right:0;bottom:0;left:0;",
    "%[data-shouldhideoverflowcontent=\"true\"]": "overflow:hidden;"
  }
}

        return skins;
    }));