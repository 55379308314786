define([
    'lodash',
    'prop-types',
    'reactDOM',
    'components',
    'componentsCore',
    'skins',
    'santa-components',
    'stripSlideShow',
    'stripSlideShowSlide/skins/skins.json'
], function (
    _,
    PropTypes,
    ReactDOM,
    components,
    componentsCore,
    skinsPackage,
    santaComponents,
    stripSlideShow,
    skinsJson
) {
    'use strict';

    const {inlineContentMixin, scrollMixin} = santaComponents.mixins;
    const fillMixin = components.mediaCommon.mediaLogicMixins.fill;

    /**
     * @class components.StripContainerSlideShowSlide
     * @extends {core.skinBasedComp}
     */
    const stripSlideShowSlide = {
        displayName: 'stripSlideShowSlide',
        mixins: [fillMixin, componentsCore.mixins.skinBasedComp, scrollMixin, componentsCore.mixins.createChildComponentMixin, inlineContentMixin],
        propTypes: {
            slideStyle: stripSlideShow.SlideShowSantaTypes.slideStyle,
            siteWidth: santaComponents.santaTypesDefinitions.siteWidth.isRequired,
            isMobileView: santaComponents.santaTypesDefinitions.isMobileView.isRequired,
            defaultContentArea: santaComponents.santaTypesDefinitions.Container.defaultContentArea.isRequired,
            shouldHideOverflowContent: PropTypes.bool,
            flexibleBoxHeight: PropTypes.bool,
            onMouseEnter: PropTypes.func,
            onMouseLeave: PropTypes.func,
            parentId: PropTypes.string
        },
        statics: {
            applyCompSpecificDomOnlyPatches(comp, slideStyle) {
                ReactDOM.findDOMNode(comp).setAttribute('data-min-height', slideStyle.minHeight);
            },
            compSpecificIsDomOnlyOverride: () => false,
            behaviors: components.mediaCommon.mediaBehaviors.fill
        },

        isScreenWidth: () => true,

        getSkinProperties() {
            const {minHeight} = this.props.slideStyle;
            const siteWidth = this.props.siteWidth;
            return {
                '': {
                    onMouseEnter: this.props.onMouseEnter,
                    onMouseLeave: this.props.onMouseLeave,
                    'data-flexibleboxheight': this.props.flexibleBoxHeight,
                    'data-parent-id': this.props.parentId,
                    'data-min-height': minHeight,
                    style: {
                        minHeight, height: '100%'
                    }
                },
                background: this.createFillLayers({
                    bgStyle: {minWidth: siteWidth},
                    mediaDimensions: {width: siteWidth}
                }),
                inlineContent: {children: this.getChildrenRenderer({contentArea: this.props.defaultContentArea})},
                inlineContentParent: {
                    style: {
                        overflowX: this.props.shouldHideOverflowContent ? 'hidden' : 'visible',
                        overflowY: this.props.shouldHideOverflowContent && !this.props.flexibleBoxHeight ? 'hidden' : 'visible'
                    }
                }
            };
        }
    };

    componentsCore.compRegistrar.register('wysiwyg.viewer.components.StripContainerSlideShowSlide', stripSlideShowSlide);
    skinsPackage.skinsMap.addBatch(skinsJson);

    return stripSlideShowSlide;
});
